import React from "react"
import { navigate } from "gatsby"
import JobListing from "../job-listing"
import JobsForm from "../job-listing/form"
import RichTextRenderer from "../../elements/RichTextRenderer"
import "./LatestJobs.scss"

interface HomePageLatestJobs {
  jobsHeading: string
  jobsContent: {
    json: string
  }
  jobs: Array<any> | null
}

const HomePageLatestJobs: React.FC<HomePageLatestJobs> = ({ jobsHeading, jobsContent, jobs }) => {
  const onExploreRolesClick = (filters: any, item: string) => {
    const searchParams: Array<string> = []

    Object.entries(filters).forEach((filter: any[]): any => {
      filter[1] = filter[0] === "keyword" && item ? item : filter[1]
      if (filter[0] === "keyword") {
        if (filter[1] !== "") {
          searchParams.push(filter.map(encodeURIComponent).join("="))
        }
      } else {
        if (filter[1].length !== 0) {
          const valuesJoin = filter[1].join("+")
          searchParams.push(encodeURIComponent(filter[0]) + "=" + encodeURIComponent(valuesJoin))
        }
      }
    })

    navigate("/jobs/?" + searchParams.join("&"))
  }

  return (
    <section className="latest-jobs latest-jobs-simple">
      <div className="container">
        <div className="row justify-content-between">
          <section className="col-lg-6 offset-xxl-0 col-xxl-6 latest-jobs-intro">
            <div className="headingContent">
              <h2 className="h1">{jobsHeading}</h2>
              <RichTextRenderer json={jobsContent} />
            </div>

            <div className="grid job-listing">{jobs && <JobListing leverJobs={jobs} />}</div>
          </section>
          <section className="col-lg-5 job-search-wrapper">
            <div className="card card-job-search">
              <h2 className="h1">START EXPLORING...</h2>
              <div className="form-wrap clearfix">
                <JobsForm onExploreRolesClick={onExploreRolesClick} calledFromHome={true} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default HomePageLatestJobs
