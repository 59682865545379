import React from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import Carousel from "../../elements/Carousel"

import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"
import ContentfulLink from "../../elements/ContentfulLink"
import pillarsImg from "../../images/homepage-pillars.jpg"
import "./style.scss"

interface HomePageIntro {
  pillarsHeading: string
  pillarsSubheading: string
  pillars: Array<IPillarSingle>
}

interface IPillarSingle {
  heading: string
  title: string
  variation: string
  content: {
    json: string
  }
  image: {
    fluid: any
    title?: string
  }
  videoAsset?: {
    title: string
    file: {
      url: string
    }
  }
  links: Array<IContentfulLink>
}

const HomePageIntro: React.FC<HomePageIntro> = ({ pillarsSubheading, pillarsHeading, pillars }) => {
  return (
    <>
      <div className="section-intro section-intro-home">
        {/* <svg className="maze" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425 419" height="419" width="425">
          <defs>
            <clipPath id="b1">
              <path fill="none" transform="translate(1155 2614)" data-name="Rectangle 535" d="M0 0h425v419H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#b1)" transform="translate(-1155 -2614)" data-name="Mask Group 392">
            <g data-name="Mask Group 13" strokeMiterlimit="10" fill="none">
              <g data-name="Group 206" strokeWidth="22" stroke="#26d1a0">
                <path d="M1642.85 2478.608l-68.67 68.67 61.038 61.04 38.15-38.15" data-name="Path 132" />
                <path d="M1703.887 2554.907l-38.15-38.15-38.15 38.151" data-name="Path 133" />
                <path data-name="Line 62" d="M1642.849 2432.83l45.778 45.778" />
                <path d="M1642.85 2417.57l-68.67 68.67-38.15-38.15" data-name="Path 134" />
                <path data-name="Line 63" d="M1551.291 2509.128l-76.298 76.298" />
                <path data-name="Line 64" d="M1513.142 2547.277l144.967 144.967" />
                <path d="M1658.107 2707.504l99.19-99.189 22.89 22.89" data-name="Path 135" />
                <path data-name="Line 65" d="M1658.109 2646.464l106.817-106.818" />
                <path data-name="Line 66" d="M1482.622 2577.796l91.559 91.559" />
                <path d="M1589.439 2684.615l106.817 106.818 129.71-129.709" data-name="Path 136" />
                <path data-name="Line 67" d="M1688.628 2738.022l106.818-106.817" />
                <path data-name="Line 68" d="M1856.484 2646.465l-76.298-76.298" />
                <path data-name="Line 69" d="M1909.892 2638.834l-160.226-160.226" />
                <path data-name="Line 70" d="M1734.407 2463.349l-76.298-76.298" />
                <path data-name="Line 71" d="M1825.965 2554.907l38.149-38.15" />
                <path data-name="Line 72" d="M1894.633 2562.536l-38.15-38.149" />
                <path data-name="Line 73" d="M1520.772 2478.608l-76.299 76.299" />
                <path data-name="Line 74" d="M1566.55 2432.83l76.299-76.299" />
                <path d="M1833.595 2501.498l-99.188-99.188-30.52 30.52" data-name="Path 137" />
                <path data-name="Line 75" d="M1711.517 2379.42l-76.298-76.298" />
                <path d="M1574.179 2730.394l-106.818-106.817 30.52-30.52" data-name="Path 138" />
                <path data-name="Line 76" d="M1589.44 2745.652l106.818 106.818" />
                <path data-name="Line 77" d="M1688.628 2860.1l91.558-91.558" />
                <path data-name="Line 78" d="M1795.446 2753.283l122.077-122.078" />
                <path data-name="Line 79" d="M1909.893 2577.796l99.188 99.188" />
                <path data-name="Line 80" d="M1948.042 2661.724l-76.299 76.298" />
                <path d="M1856.484 2753.282l-68.67 68.67-30.52-30.52" data-name="Path 139" />
                <path d="M1444.473 2585.426l-38.15 38.15 38.148 38.15" data-name="Path 140" />
                <path d="M1459.731 2676.986l53.409 53.408 30.52-30.52" data-name="Path 141" />
                <path d="M1536.03 2753.284l83.927 83.928 30.52-30.52" data-name="Path 142" />
                <path d="M1642.847 2860.102l53.409 53.408 68.668-68.668" data-name="Path 143" />
                <path d="M1505.51 2432.83l160.23-160.23 53.409 53.409" data-name="Path 144" />
                <path data-name="Line 81" d="M1604.7 2394.68l-30.52-30.519" />
                <path data-name="Line 82" d="M1734.407 2341.271l91.558 91.559" />
                <path data-name="Line 83" d="M1841.225 2448.089l137.337 137.337" />
                <path data-name="Line 84" d="M1879.374 2547.277l30.52-30.52" />
                <path data-name="Line 85" d="M1680.998 2623.575l30.52 30.52" />
                <path data-name="Line 86" d="M1490.252 2448.089l-45.779 45.779" />
                <path d="M1413.954 2493.868l-68.67 68.67 30.52 30.52 61.038-61.04 15.26 15.26" data-name="Path 145" />
                <path d="M1307.136 2585.427l152.593 152.594 30.52-30.52" data-name="Path 146" />
                <path d="M1482.621 2760.913l91.558 91.558 30.52-30.52" data-name="Path 147" />
                <path data-name="Line 87" d="M1597.07 2875.359l91.558 91.558" />
                <path d="M1673.367 2997.438l114.448-114.449-30.52-30.52" data-name="Path 148" />
                <path data-name="Line 88" d="M1810.705 2860.1l167.857-167.857" />
                <path data-name="Line 89" d="M1848.854 2821.95l-30.52-30.52" />
                <path data-name="Line 90" d="M1970.931 2638.834l38.15-38.149" />
                <path data-name="Line 91" d="M2039.6 2646.464l-38.15-38.149" />
                <path d="M2070.12 2615.945l-106.818-106.818-30.521 30.52" data-name="Path 149" />
                <path data-name="Line 92" d="M1963.302 2509.128l-61.039-61.04" />
                <path data-name="Line 93" d="M1887.003 2432.829l-106.817-106.818" />
                <path data-name="Line 94" d="M1803.075 2409.94l30.52-30.52" />
                <path d="M1764.926 2310.752l-68.668-68.669-61.04 61.04" data-name="Path 150" />
                <path data-name="Line 95" d="M1528.402 2409.94l-22.89-22.89" />
                <path data-name="Line 96" d="M1696.258 2242.084l-22.89-22.89" />
                <path d="M1375.805 2654.095l-30.52 30.52-68.669-68.668" data-name="Path 151" />
                <path data-name="Line 97" d="M1703.887 2493.868l61.04 61.04" />
                <path data-name="Line 98" d="M1276.617 2570.166l137.338-137.337" />
                <path data-name="Line 99" d="M1452.103 2486.238l-45.779-45.779" />
                <path d="M1581.809 2295.49l-129.707 129.708-76.297-76.297-190.747 190.747" data-name="Path 152" />
                <path data-name="Line 100" d="M1597.07 2280.232l76.298-76.298" />
                <path data-name="Line 101" d="M1368.175 2707.503l160.226 160.226" />
                <path data-name="Line 102" d="M1543.661 2882.99l129.708 129.707" />
                <path d="M1284.246 2623.577l-61.04-61.04 160.231-160.23" data-name="Path 153" />
                <path d="M1551.292 2264.973l-99.19 99.19-30.52-30.52 221.267-221.267" data-name="Path 154" />
                <path data-name="Line 103" d="M1658.109 2142.895l190.745 190.746" />
                <path data-name="Line 104" d="M1864.114 2348.9l129.708 129.708" />
                <path data-name="Line 105" d="M1925.152 2470.978l30.52-30.52" />
                <path data-name="Line 106" d="M2009.081 2493.868l91.558 91.558" />
                <path d="M1543.66 2211.566l30.52 30.52 68.668-68.67" data-name="Path 155" />
                <path d="M1154.538 2554.908l114.448 114.447 30.52-30.52" data-name="Path 156" />
                <path data-name="Line 107" d="M1291.877 2692.244l122.077 122.077" />
                <path d="M1429.212 2829.581l175.486 175.487 30.52-30.52" data-name="Path 157" />
              </g>
              <path
                strokeDasharray="7, 8"
                strokeWidth="3"
                stroke="#fff"
                d="M1158.842 2813.343l140.706-143.5 46.756 46.118 30.559-31.278 84.1 83.5 29.708-30.209 23.022 23.546 29.8-29.479 22.293 21.058 29.206-31.646-28.458-28.14 37.3-39.1"
                data-name="Path 205"
              />
              <path
                className="maze-line"
                strokeWidth="7"
                stroke="#10191d"
                d="M1158.842 2813.343l140.706-143.5 46.756 46.118 30.559-31.278 84.1 83.5 29.708-30.209 23.022 23.546 29.8-29.479 22.293 21.058 29.206-31.646-28.458-28.14 37.3-39.1"
                data-name="Path 205"
              />
            </g>
          </g>
        </svg> */}
        <div className="container">
          <div className="row justify-content-center text-md-center">
            <div className="col-lg-10 col-xxl-8">
              <h2 className="h1">{pillarsHeading}</h2>
              <p>{pillarsSubheading}</p>
            </div>
          </div>
        </div>
        <div className="graphics">
          <img src={pillarsImg} alt="Pillars"/>
        </div>
      </div>
      <div className="expander">
        <Carousel
          element="js-expand-carousel"
          showBullets
          showArrows
          options={{
            type: "slider",
            perView: 1,
            gap: 0,
            animationDuration: 1000,
          }}
        >
          {pillars.map(pillar => (
            <li className="glide__slide pillar-slide" key={pillar.title}>
              {(pillar.videoAsset || pillar.image) &&
                (pillar.videoAsset ? (
                  <video
                    width="1600"
                    height="900"
                    muted
                    loop
                    autoPlay
                    playsInline
                    poster={pillar.image?.fluid?.src || "#"}
                  >
                    <source src={pillar.videoAsset?.file?.url || "#"} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={pillar.image?.fluid?.src || "#"}
                    alt={pillar.image?.title || ""}
                    width={1580}
                    height={900}
                  />
                ))}
              <div className="content container">
                <div
                  className={`inner ${pillar.variation === "Right"
                    ? "pillar-right"
                    : pillar.variation === "Center"
                      ? "pillar-center"
                      : "pillar-left"
                    }`}
                >
                  <h3 className="pillar-title">{pillar.heading}</h3>
                  <blockquote className="blockquote">
                    <RichTextRenderer json={pillar.content} />
                  </blockquote>
                  {pillar.links?.map((link, index) => (
                    <ContentfulLink className="btn-link" link={link} key={index} />
                  ))}
                </div>
              </div>
            </li>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default HomePageIntro
