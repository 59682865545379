import classNames from "classnames"
import React from "react"
import Carousel from "../../elements/Carousel"
import "./style.scss"



interface HomePageLogos {
  logos: Array<Logo>
  title?: string
}

interface Logo {
  title?: string
  file: {
    url: string
  }
}

const HomePageLogos: React.FC<HomePageLogos> = ({ title, logos }) => {
  return (
    <section className="section-logos">
      <div className="container">
      {title && (
        <div className="text-center pb-4 pb-lg-5">
          <h2 className="h2 text-warning">{title}</h2>
        </div>
      )}
      <Carousel
        element="js-logo-carousel"
        options={{
          type: "slider",
          perView: logos.length >= 4 ? 4 : logos.length,
          autoplay: 3000,
          rewind: true,
          rewindDuration: 2000,
          animationTimingFunc: "ease",
          bound: true,
          breakpoints: {
            600: {
              perView: logos.length >= 2 ? 2 : logos.length,
              peek: { before: 0, after: 50 },
            },
            992: {
              perView: logos.length >= 3 ? 3 : logos.length,
            },
            1200: {
              perView: logos.length >= 3 ? 3 : logos.length,
            },
          },
        }}
        showArrows={true}
        className="logos"
      >
          {logos.map(logo => {
            const objectFitImgClass = !logo?.file?.url?.includes("svg")
            return (
              <li className="home-logo-item" key={logo.title}>
                <img
                  className={classNames("img-fluid", { "home-logo-item-object-fit": objectFitImgClass })}
                  src={logo.file.url}
                  alt={logo.title || ""}
                  width="109"
                  height="165"
                />
              </li>
            )
          })}
      </Carousel>
      </div>
    </section>
  )
}

export default HomePageLogos
