import React from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import ContentfulLink from "../../elements/ContentfulLink"
import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"

import "../../layouts/Primary/styles/skin/modules/_expander.scss"
import "./style.scss"

interface HomePageDiversity {
  diversityContent: {
    json: any
  }
  diversityLink: Array<IContentfulLink>
  diversityImage: {
    fluid: any
    title?: string
  }
}

const HomePageDiversity: React.FC<HomePageDiversity> = ({ diversityContent, diversityLink, diversityImage }) => {
  return (
    <div className="diversity expander">
      <div className="mask" />
      <div className="glide">
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            <div className="glide__slide">
            <img srcSet={diversityImage?.fluid?.images?.fallback?.srcSet} alt={diversityImage.title || ""} />
              <div className="content container">
                <div className="inner">
                  <RichTextRenderer json={diversityContent} />
                  <ContentfulLink className="btn-link" link={diversityLink[0]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePageDiversity
