import React, { FunctionComponent } from "react"

const PlainText: FunctionComponent<{ content: string; className?: string }> = ({
  content,
  className,
}) => {
  const contentWithBr =
    content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + "<br />" + "$2") ||
    ""

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: contentWithBr }}
    />
  )
}

export default PlainText
