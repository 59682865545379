import React from "react"
import PlainText from "../../elements/PlainText"
import greenWave from "../../images/green-wave.jpg"
import * as classes from "./HomePagePrimaryContent.module.scss"
import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"
import CTA from "../../elements/CTA"
import classNames from "classnames"

interface HomePagePrimaryContent {
  introText: string
  introButton: IContentfulLink
}

const HomePagePrimaryContent: React.FC<HomePagePrimaryContent> = ({ introText, introButton }) => {
  return (
    <section className={classes.primaryContent}>
      <div className={classNames("container", classes.content)}>
        <div className={classes.leftContent}>
          <PlainText content={introText} />
          {introButton &&<CTA {...introButton} />}
        </div>
        <div className={classes.rightContent}>
          <img src={greenWave} alt="GreenWave"/>
        </div>
      </div>
    </section>
  )
}

export default HomePagePrimaryContent
