import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import CTA from "../../elements/CTA"
import RichTextRenderer from "../../elements/RichTextRenderer"
import "./style.scss"
import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"

interface HomePageStudents {
  studentsImage: {
    fluid: any
    title?: string
  }
  studentsCompass: {
    fluid: any
    title?: string
  }
  studentsContent: {
    json: string
  }
  studentsButton: IContentfulLink
}

const HomePageStudents: React.FC<HomePageStudents> = ({
  studentsImage,
  studentsCompass,
  studentsContent,
  studentsButton,
}) => {
  return (
    <div className="students clearfix">
      <div className="container position-relative">
        <h2 className="heading">Students & Graduates</h2>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {studentsImage && <GatsbyImage image={studentsImage.fluid} alt={studentsImage.title || ""} />}

            <div className="students-content">
              {studentsCompass && <GatsbyImage image={studentsCompass.fluid} alt={studentsCompass.title || ""} />}
              {studentsContent && <RichTextRenderer json={studentsContent} />}
              {studentsButton && <CTA {...studentsButton} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageStudents
