import React, { useEffect, useState } from "react"
import { PageProps, graphql } from "gatsby"

import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import HomePageHero from "../components/home-hero"
import { IContentfulLink } from "../elements/ContentfulLink/contentful-link"
import HomePagePrimaryContent from "../components/home-primary-content"
import HomeMazeWrap from "../components/home-maze-wrap"
import HomePageIntro from "../components/home-intro"
import HomePageLatestJobs from "../components/home-latest-jobs"
import HomePageTeams from "../components/home-teams"
import QuoteBanner from "../components/quote-banner"
import HomePageGallery from "../components/home-gallery"
import HomePageStudents from "../components/home-students"
import HomePageDiversity from "../components/home-diversity"
import HomePageLogos from "../components/home-logos"
import RichTextRenderer from "../elements/RichTextRenderer"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { GatsbyImage } from "gatsby-plugin-image"

export interface IContentfulCareersHomePage {
  title: string
  seoTitle: string
  seoDescription: string
  heroTagline: string
  heroHeading: string

  // Link
  heroButton: IContentfulLink
  introButton: IContentfulLink
  // Plain Text / Text Area
  introText: {
    introText: string
  }

  pillarsHeading: string
  pillarsSubheading: {
    pillarsSubheading: string
  }
  pillars: []

  jobsTagline: string
  jobsIntro: {
    json: string
  }

  teamsIntro: {
    json: string
  }
  teams: []

  quoteAuthor: {
    quoteAuthor: string
  }
  quoteContent: {
    quoteContent: string
  }
  quoteImage: {
    fixed: any
    fluid: any
  }

  // Rich Text
  galleryIntro: {
    json: string
  }
  galleryCards: []

  diversityHeading: {
    json: string
  }
  diversityContent: {
    json: string
  }
  diversityImage: {
    fluid: any
  }
  diversityLinks: Array<IContentfulLink>

  studentsImage: {
    fluid: any
  }
  studentsCompass: {
    fluid: any
  }
  studentsContent: {
    json: string
  }
  studentsButton: IContentfulLink
  // Image, use webp when possible
  // Set quality to 100 in graphql
  centralImage: {
    fixed: any
    fluid: any
    title: string
  }

  logos: []
}

interface IContentfulCareersHomePageProps {
  contentfulCareersHomePage: IContentfulCareersHomePage
  allLever: {
    nodes: Array<any>
  }
}

interface ICareersHomePageContext {
  id: string
  slug: string
  locale: string
}

const HomePage: React.FC<PageProps<IContentfulCareersHomePageProps, ICareersHomePageContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const locale = pageContext.locale || "en-US"
  // const { t } = useTranslation("strings")
  const seoData = {
    title: data.contentfulCareersHomePage.seoTitle || data.contentfulCareersHomePage.title,
    description: data.contentfulCareersHomePage.seoDescription,
    image: "",
  }
  const getFeaturesJobs = () =>
    data.allLever.nodes.filter(posting => posting.additionalPlain.indexOf("#featured") !== -1).slice(0, 5)

  const [featuredJobs, setFeaturedJobs]: [any[] | null, Function] = useState(null)
  useEffect(() => setFeaturedJobs(getFeaturesJobs()), [data.allLever])

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      {/* Layout contains menus */}

      <Layout overflowEnable seoData={seoData} dispatch={() => false}>
        {/* Sections (components), each component has more info how to render that data type */}
        <HomePageHero
          tagline={data.contentfulCareersHomePage.heroTagline}
          heading={data.contentfulCareersHomePage.heroHeading}
          button={data.contentfulCareersHomePage.heroButton}
        />
        <HomePageIntro
          pillarsHeading={data.contentfulCareersHomePage.pillarsHeading}
          pillarsSubheading={data.contentfulCareersHomePage.pillarsSubheading.pillarsSubheading}
          pillars={data.contentfulCareersHomePage.pillars}
        />
        <HomePagePrimaryContent introText={data.contentfulCareersHomePage.introText.introText} introButton={data.contentfulCareersHomePage.introButton}/>
        <HomePageLatestJobs
          jobsHeading={data.contentfulCareersHomePage.jobsTagline}
          jobsContent={data.contentfulCareersHomePage.jobsIntro}
          jobs={featuredJobs}
        />

        <div className="full-width-image">
          <GatsbyImage
            image={{ ...data.contentfulCareersHomePage.centralImage.fluid, aspectRatio: 21 / 9 }}
            alt={data.contentfulCareersHomePage.centralImage.title || ""}
          />
        </div>

        <HomePageTeams
          teamsIntro={data.contentfulCareersHomePage.teamsIntro}
          teams={data.contentfulCareersHomePage.teams}
        />

        <QuoteBanner
          quoteAuthor={data.contentfulCareersHomePage.quoteAuthor.quoteAuthor}
          quoteContent={data.contentfulCareersHomePage.quoteContent.quoteContent}
          quoteImage={data.contentfulCareersHomePage.quoteImage}
        />

        <div className="section-intro">
            <div className="row justify-content-center text-center">
              <div className="col-lg-10 col-xxl-8">
                <RichTextRenderer json={data.contentfulCareersHomePage.diversityHeading} />
              </div>
            </div>
        </div>

        <HomePageDiversity
          diversityContent={data.contentfulCareersHomePage.diversityContent}
          diversityImage={data.contentfulCareersHomePage.diversityImage}
          diversityLink={data.contentfulCareersHomePage.diversityLinks}
        />

        <HomePageStudents
          studentsImage={data.contentfulCareersHomePage.studentsImage}
          studentsCompass={data.contentfulCareersHomePage.studentsCompass}
          studentsContent={data.contentfulCareersHomePage.studentsContent}
          studentsButton={data.contentfulCareersHomePage.studentsButton}
        />

        <HomePageLogos logos={data.contentfulCareersHomePage.logos} />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default HomePage

export const query = graphql`
  query($id: String!) {
    allLever(sort: { order: DESC, fields: createdAt }) {
      nodes {
        ...JobPosting
      }
    }
    contentfulCareersHomePage(id: { eq: $id }) {
      title
      seoTitle
      seoDescription

      heroTagline
      heroHeading
      heroButton {
        ...ContentfulLink
      }
      introText {
        introText
      }
      introButton {
        ...ContentfulLink
      }
      pillarsHeading
      pillarsSubheading {
        pillarsSubheading
      }
      pillars {
        ...ComponentCard
      }

      jobsTagline
      jobsIntro {
        ...RichTextField
      }

      teamsIntro {
        ...RichTextField
      }
      teams {
        ...ComponentCard
      }

      quoteContent {
        quoteContent
      }
      quoteAuthor {
        quoteAuthor
      }
      quoteImage {
        fixed: gatsbyImageData(layout: FIXED width: 757 quality: 100 formats: [AUTO, WEBP])         
        fluid: gatsbyImageData(quality: 100 formats: [AUTO, WEBP])
        
        title
      }

      galleryIntro {
        ...RichTextField
      }
      galleryCards {
        id
        ...ComponentCard
      }

      diversityHeading {
        ...RichTextField
      }
      diversityContent {
        __typename
        ...RichTextField
      }
      diversityLinks {
        ...ContentfulLink
      }
      diversityImage {
        fluid: gatsbyImageData(width: 2048 height: 1500 quality: 100 formats: [AUTO, WEBP])         
        title
      }

      studentsImage {
        fluid: gatsbyImageData(width: 1056 height: 512 quality: 100 formats: [AUTO, WEBP])        
        title
      }
      studentsCompass {
        fluid: gatsbyImageData(width: 1056 height: 512 quality: 100 formats: [AUTO, WEBP])        
        title
      }
      studentsContent {
        ...RichTextField
      }
      studentsButton {
        ...ContentfulLink
      }

      logos {
        ... {
          title
          file {
            url
          }
        }
      }

      centralImage {
        fixed: gatsbyImageData(layout: FIXED width: 500 quality: 100 formats: [AUTO, WEBP])
        
        fluid: gatsbyImageData(width: 2048 quality: 100 formats: [AUTO, WEBP])     
        
        title
      }
    }
  }
`
