import React from "react"
import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"
import CTA from "../../elements/CTA"

import "./style.scss"

interface HomePageHero {
  tagline: string
  heading: string
  button: IContentfulLink
}

const HomePageHero: React.FC<HomePageHero> = ({ tagline, heading, button }) => {
  return (
    <section className="hero hero-home">
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <p className="sub-heading">{tagline}</p>
            <h1>{heading}</h1>
            <CTA {...button} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomePageHero
